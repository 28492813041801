<template>
	<v-container class="px-5 fill-height d-flex justify-center">
		<div class="login-div mx-lg-auto">
			<div v-if="error" class="col-sm-12 col-md-12">
				<v-alert class="mt-6" border="left" type="error" dark>
					{{ errorMsg }}
				</v-alert>
			</div>
			<h1 class="text-center mb-6 primary--text">{{ $t("login.sign_in") }}</h1>
			<v-form ref="form" v-model="valid" @submit.prevent="login">
				<v-text-field dense :placeholder="$t('login.username')" persistent-placeholder outlined required
					:rules="userNameRules" v-model="userName"></v-text-field>
				<v-text-field :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'" class="input-group--focused"
					@click:append="showPassword = !showPassword" outlined dense required :rules="passwordRules"
					:placeholder="$t('login.password')" persistent-placeholder v-model="password"></v-text-field>
				<div class="mb-4">
					<v-checkbox color="#F36E21" class="mt-0" :label="$t('login.remember_me')" hide-details
						v-model="rememberMe"></v-checkbox>
				</div>
				<v-btn small class="bg-blue-dark" height="40px" width="100%" type="submit">
					<h3 class="font-weight-regular mb-0">
						{{ $t("login.login") }}
					</h3>
				</v-btn>
			</v-form>
		</div>
		<DefaultLoading v-if="loading" :loading="loading" />
	</v-container>
</template>

<script>
import { SignaturesService } from "../../services/signatures-service";
import DefaultLoading from "../../components/shared/DefaultLoading.component";
import moment from "moment";
export default {
	data: function () {
		return {
			showPassword: false,
			loading: false,
			userName: "",
			password: "",
			rememberMe: "",
			error: false,
			errorMsg: "",
			validate: [],
			valid: true,
			userNameRules: [(v) => !!v || ""],
			passwordRules: [(v) => !!v || ""],
		};
	},
	components: {
		DefaultLoading,
	},
	computed: {
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		}
	},
	methods: {
        submitLogin() {
            // Simula el clic en el botón de acceso
            if (this.$refs.loginButton) {
                this.$refs.loginButton.$el.click();
            }
        },
		login() {
			if (this.checkValidation()) {
				this.loading = true;
				SignaturesService.login(this.userName, this.password)
					.then((response) => {
						if (
							response.status &&
							response.status == 200 &&
							response.data &&
							response.data.apiToken
						) {
							if(response.data.association){
								localStorage.setItem('currentAssociation', response.data.association);
								localStorage.setItem('isCustomizedUser', true);
							}
							
							response.data["rememberMe"] = this.rememberMe;
							this.$store
								.dispatch("common/setLoginUser", response.data)
								.then(() => {
									this.isLogging();
								});
						} else {
							this.error = true;
							this.errorMsg = this.$t("login.invalid_login_details");
							this.loading = false;
						}
					})
					.catch(() => {
						this.error = true;
						this.errorMsg = this.$t("login.invalid_login_details");
						this.loading = false;
					});
			}
		},
		isLogging() {
            this.authid="";
            let association=localStorage.getItem("currentAssociation");
            let isCustomized=localStorage.getItem("isCustomizedUser");
            console.log(association);
            console.log(localStorage.getItem("auth_user_token"));
            localStorage.setItem("user", true);
            localStorage.removeItem("list");

			if (localStorage.getItem("auth_user_token") != "") {
                this.$router.push({
                    name: isCustomized ? "custom-my-signatures" : "my-signatures",
                    params: isCustomized ? { association } : {}
                });

			} else {
				return false;
			}
		},
		checkValidation() {
			return this.$refs.form.validate();
		},
	},
	mounted() {
        console.log("Estado de hasAssociation:", this.hasAssociation);
		localStorage.removeItem("auth_user_token");
		localStorage.removeItem("request_id");
		localStorage.removeItem("auth_id");
		localStorage.removeItem("request_id");
		localStorage.removeItem("currentAssociation");
		localStorage.removeItem("file_name");
		localStorage.removeItem("type");
		localStorage.removeItem("isCustomizedUser");
		localStorage.removeItem("auth_citizen_id");
		localStorage.removeItem("access_token");
		localStorage.removeItem("transaction_id");
		localStorage.removeItem("list");
		localStorage.removeItem("multiple_token");
		localStorage.removeItem("documentCheckList");


		const rememberStatus = localStorage.getItem("remember_me");
		if (rememberStatus) {
			const timeDiff = moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
				moment(rememberStatus, "DD/MM/YYYY HH:mm:ss"),
				"minutes"
			);

			if (timeDiff < 1) {
				this.loading = true;
				setTimeout(this.isLogging(), 5000);
			} else {
				localStorage.setItem("remember_me", "");
			}
		}
	},
};
</script>
<style>
.validation-error .v-input__slot {
	border: 1px solid rgb(128, 0, 17);
}
</style>
